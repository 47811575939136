import React, { FC } from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { appWithTranslation } from "next-i18next";
import type { AppProps } from "next/app";

import ErrorBoundary from "@components/ErrorBoundary";
import { MessageContextProvider } from "@components/Message";
import { BrowserFeatureCheckProvider } from "@lib/browser-version-message";
import { appWithLocales } from "@lib/custom-locales";
import Fonts from "@lib/fonts";
import { MediaContextProvider } from "@lib/media";
import theme from "@lib/theme";
import { AuthProvider } from "@modules/auth";
import FeatureProvider from "@modules/features/FeatureProvider";
import { createLayout } from "@modules/layout";
import { useResetStoredOrder } from "@modules/order";

import i18nConfig from "../next-i18next.config";

import "../public/assets/css/chatbot.css";

const App: FC<AppProps> = ({ Component, pageProps: { hasAuthCookie, features, ...pageProps } }: AppProps) => {
  const Layout = createLayout(Component, pageProps.defaultLocale);

  useResetStoredOrder();
  return (
    <ErrorBoundary>
      <ChakraProvider theme={theme}>
        <Fonts />
        <MediaContextProvider>
          <AuthProvider hasAuthCookie={hasAuthCookie}>
            <FeatureProvider features={features}>
              <MessageContextProvider>
                <BrowserFeatureCheckProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </BrowserFeatureCheckProvider>
              </MessageContextProvider>
            </FeatureProvider>
          </AuthProvider>
        </MediaContextProvider>
      </ChakraProvider>
    </ErrorBoundary>
  );
};
export default appWithTranslation(appWithLocales(App), i18nConfig as any);
